import React, { useState, useEffect, useRef, useCallback } from 'react';

import Pill from './pill.js';
import Marquee from './marquee.js';

function ActionBar() {

    return <section className="fixed flex flex-col justify-center items-center bottom-0 w-full h-16 sm:h-20 z-10 bg-blue bg-opacity-5 backdrop-filter backdrop-blur-md">
        <div className="flex justify-between px-5 w-full max-w-screen-2xl">
            <img src="img/demsky/demsky-ar-clip-mini.png" className="h-full"/>
            <Marquee text="DROPS 10 / 21 / 21 AT 12AM CST – TAP HERE TO PRE-REGISTER FOR PURCHASE – " />
            <Pill className="lg:text-3xl">PRE●REGISTER</Pill>
        </div>
    </section>;
}

export default ActionBar;