import { useState, useEffect, useCallback } from 'react';

import throttle from 'lodash.throttle';

export function useScrollPosition() {

    const [ scrollPosition, setSrollPosition ] = useState( { x: 0, y: 0 } );

    const handleScroll = useCallback( throttle( ( e ) => {
        setSrollPosition( { x: window.pageXOffset, y: window.pageYOffset } );
    }, 1000 / 60 ), [] );

    useEffect( () => {
        window.addEventListener( 'scroll', handleScroll, { passive: true } );
        return () => {
            window.removeEventListener( 'scroll', handleScroll );
        };
    }, [] );

    return scrollPosition;
}