import React from 'react';

function Socials() {

    return <>
        <div className="flex items-center mb-5"><div className="w-10 h-10 mr-3"><img src="/img/demsky/demsky-icon-demsky.jpg" className="w-full rounded-xl" /></div>jdemsky.com</div>
        <div className="flex items-center mb-5"><div className="w-10 h-10 mr-3"><img src="/img/demsky/demsky-icon-neofvtvro.jpg" className="w-full rounded-xl" /></div>neofvtvro.com</div>
        <div className="flex items-center"><div className="w-10 h-10 mr-3"><img src="/img/demsky/demsky-icon-insta.svg" className="w-full rounded-xl" /></div>@demsky</div>
    </>;

}

export default Socials;