import React, { useState, useEffect, useRef, useCallback } from 'react';

import * as THREE from 'three';
import throttle from 'lodash.throttle';
import Parallax from './parallax';

import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useScrollPosition } from './scroll-position';
import { useSpring, config } from 'react-spring';
import { useInView } from 'react-intersection-observer';

function ModelViewer( props ) {

    const scene = useRef();
    const camera = useRef();
    const renderer = useRef();
    const model = useRef();

    const { debug, gltfUrl, environmentUrl, backgroundUrl } = props;

    const viewerContainer = useRef();
    const viewerCanvas = useRef();

    const [ hasDeviceOrientation, setHasDeviceOrientation ] = useState( 'not granted' );
    const [ hasDeviceOrientationCapability, setHasDeviceOrientationCapability ] = useState( false );

    const scrollPosition = useScrollPosition();
    const [ orientation, setOrientation ] = useState( { alpha: 0, beta: 0, gamma: 0, } );

    const frameRef = useRef();
    const mounted = useRef();

    const { ref, inView, entry } = useInView();

    const { rotationX, rotationY } = useSpring( {
        rotationX: orientation.beta / 2,
        rotationY: scrollPosition.y / 6 - orientation.gamma,
        config: config.fast
    } );

    useEffect( () => {

        if ( viewerCanvas.current && viewerContainer.current ) {

            scene.current = new THREE.Scene();
            camera.current = new THREE.PerspectiveCamera( 75, viewerContainer.current.clientWidth / viewerContainer.current.clientHeight, 0.1, 1000 );
            renderer.current = new THREE.WebGLRenderer( {
                antialias: true,
                alpha: true,
                canvas: viewerCanvas.current,
            } );

            renderer.current.setSize( viewerContainer.current.clientWidth, viewerContainer.current.clientHeight );
            renderer.current.outputEncoding = THREE.sRGBEncoding;
            renderer.current.setPixelRatio( 2 );
            camera.current.position.z = 2.5;

            const pointLight = new THREE.PointLight( 0xffffff, 1, 500 );
            pointLight.position.set( 50, 0, 50 );
            scene.current.add( pointLight );

            new RGBELoader().load( environmentUrl, ( texture ) => {

                texture.mapping = THREE.EquirectangularReflectionMapping;
                scene.current.environment = texture;

                const roughnessMipmapper = new RoughnessMipmapper( renderer.current );

                new GLTFLoader().setDRACOLoader( new DRACOLoader().setDecoderPath( 'js/libs/draco/gltf/' ) ).load( gltfUrl, function ( gltf ) {

                    model.current = gltf;
                    model.current.scene.traverse( ( child ) => {
                        if ( child.isMesh ) {
                            roughnessMipmapper.generateMipmaps( child.material );
                        }
                    } );

                    scene.current.add( model.current.scene );
                    roughnessMipmapper.dispose();

                    mounted.current = true;
                    frameRef.current = requestAnimationFrame( animate );

                } );
            } );
        }

        checkDeviceOrientationCapability();
        window.addEventListener( 'resize', onWindowResize, false );

        return () => {
            mounted.current = false;
            cancelAnimationFrame( frameRef.current );
            window.removeEventListener( 'resize', onWindowResize, false );
            window.removeEventListener( 'deviceorientation', handleDeviceOrientation );
        };

    }, [] );

    useEffect( () => {
        if ( inView ) {
            mounted.current = true;
            frameRef.current = requestAnimationFrame( animate );
        } else {
            mounted.current = false;
            cancelAnimationFrame( frameRef.current );
        }
    }, [ inView ] );

    const animate = () => {
        if ( viewerCanvas.current && mounted.current === true && model.current ) {
            model.current.scene.rotation.y = rotationY.get() * -( Math.PI / 180 ) + 0.6;
            model.current.scene.rotation.x = rotationX.get() * -( Math.PI / 180 );
            renderer.current.render( scene.current, camera.current );

            frameRef.current = requestAnimationFrame( animate );
        }
    };

    const onWindowResize = () => {
        if ( renderer && viewerCanvas.current ) {
            camera.current.aspect = viewerContainer.current.clientWidth / viewerContainer.current.clientHeight;
            camera.current.updateProjectionMatrix();
            renderer.current.setSize( viewerContainer.current.clientWidth, viewerContainer.current.clientHeight );
        }
    };

    const handleDeviceOrientation = useCallback( throttle( ( e ) => {
        setOrientation( { beta: e.beta, alpha: e.alpha, gamma: e.gamma } );
    }, 1000 / 60 ), [] );

    const checkDeviceOrientationCapability = () => {
        if ( typeof DeviceOrientationEvent !== 'function' ) {
            return setHasDeviceOrientationCapability( false );
        }
        if ( typeof DeviceOrientationEvent.requestPermission !== 'function' ) {
            return setHasDeviceOrientationCapability( false );
        }
        setHasDeviceOrientationCapability( true );
    };

    useEffect( () => {
        enableDeviceOrientation();
    }, [ hasDeviceOrientationCapability ] );

    const enableDeviceOrientation = () => {
        if ( hasDeviceOrientationCapability && hasDeviceOrientation === 'not granted' ) {
            DeviceOrientationEvent.requestPermission().then( ( result ) => {
                setHasDeviceOrientation( () => {
                    if ( result === 'granted' ) {
                        window.addEventListener( 'deviceorientation', handleDeviceOrientation );
                        return 'granted';
                    }
                    return 'denied';
                } );
            } );
        }
    };

    return <>
        <div className="relative w-full flex-1 mb-16" ref={ref}>
            <div className="relative flex-1 h-full bg-center bg-contain bg-no-repeat" style={{ backgroundImage: `url(${backgroundUrl})` }}></div>
            <section className="absolute top-0 w-full h-full overflow-hidden" ref={viewerContainer}>
                <canvas className="relative pointer-events-none" ref={viewerCanvas}></canvas>
                {( hasDeviceOrientationCapability && hasDeviceOrientation === 'not granted' ) &&
                    <button className="btn btn-blue absolute bottom-5 right-5" onClick={() => { enableDeviceOrientation(); }}>Enable device orientation</button>
                }
            </section>
            <div className="flex flex-col absolute top-10 w-full">
                <div className="self-center w-full md:w-5/6">
                    <div className="relative ml-8 w-20 sm:w-32 md:w-40">
                        <img className="w-full" src="img/demsky/demsky-animated-badge-inner.svg" />
                        <Parallax className="absolute w-full top-0 left-0" type="rotate3d" speed={100}>
                            <img className="w-full animate-spin-slow" src="img/demsky/demsky-animated-badge-ring.svg" />
                        </Parallax>
                    </div>
                </div>
            </div>
        </div>

        {debug && <section className="p-5 bg-gray-100">
            <ul>
                <li>DeviceOrientationCapability: {hasDeviceOrientationCapability.toString()}</li>
                <li>DeviceOrientation: {hasDeviceOrientation.toString()}</li>
                <li>alpha: {orientation.alpha}</li>
                <li>beta: {orientation.beta}</li>
                <li>gamma: {orientation.gamma}</li>
            </ul>
        </section>
        }
    </>;
}

export default ModelViewer;