import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { Disclosure, Menu, Transition } from '@headlessui/react';
import { XIcon, MenuAlt4Icon } from '@heroicons/react/outline';

const navigation = [
    { name: 'Index', href: '/', current: true }
];

function Navbar() {

    return <Disclosure as="nav" className="fixed z-10 w-full bg-transparent">
        {( { open } ) => (
            <>
                <div className={`p-5 ${open ? 'bg-white' : ''}`}>
                    <div className="relative flex items-center space-between">
                        <div className=" flex items-center">
                            {/* Mobile menu button*/}
                            <Disclosure.Button className="inline-flex items-center justify-center">
                                <img className="block h-8 w-8 sm:w-14 sm:h-14" src="img/demsky/home-button.svg" />
                                <span className="sr-only">Open main menu</span>
                            </Disclosure.Button>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center">
                            {/* Profile dropdown */}
                            <Menu as="div" className="ml-5 relative">
                                <div>
                                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                            className="h-8 w-8 sm:w-14 sm:h-14 rounded-full"
                                            src="https://images.unsplash.com/photo-1554188248-986adbb73be4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                            alt=""
                                        />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <Menu.Item>
                                            {( { active } ) => (
                                                <a href="#" className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}>
                                                    Your Profile
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {( { active } ) => (
                                                <a href="#" className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}>
                                                    Settings
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {( { active } ) => (
                                                <a href="#" className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}>
                                                    Sign out
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>

                <Disclosure.Panel className="">
                    <div className="px-5 pt-2 pb-3 space-y-1 bg-white">
                        {navigation.map( ( item ) => (
                            <NavLink exact key={item.name} to={item.href} aria-current={item.current ? 'page' : undefined} activeClassName="bg-gray-900 text-white" className={'text-black hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-black font-medium'}>{item.name}</NavLink>
                        ) )}
                    </div>
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>;
}

export default Navbar;