import React from 'react';

import ModelViewer from '../components/model-viewer.js';
import ActionBar from '../components/action-bar.js';
import Parallax from '../components/parallax.js';
import Socials from '../components/socials.js';
import Layout from '../components/layout.js';
import Pill from '../components/pill.js';


const styles = {
    h1: 'uppercase self-center mt-32 sm:mt-36 md:mt-40 px-8 w-full md:w-5/6 md:px-10',
    h2: 'font-serif uppercase text-3xl mb-10 sm:text-6xl sm:mb-12 md:text-7xl md:mb-14 col-span-12',
    p: {
        sm: 'sm:text-2xl col-span-12 md:col-span-8 md:col-start-5 lg:col-span-7 lg:col-start-5',
        md: 'sm:text-2xl col-span-12 md:col-span-8 md:col-start-3 lg:col-span-7 lg:col-start-5',
        lg: 'sm:text-2xl col-span-12 md:col-span-10 md:col-start-3 lg:col-span-7 lg:col-start-5'
    }
};

function Index() {
    return <Layout>
        <article className="flex flex-col text-blue">

            <header className="h-screen flex flex-col">
                <h1 className={`${styles.h1}`}><img src="img/demsky/demsky-logo.svg" /></h1>
                <ModelViewer
                    debug={false}
                    gltfUrl="/gltf/demsky/object-01/26-Demsky-chrome-V4-straight-UV-compressed.gltf"
                    environmentUrl="/gltf/demsky/object-01/royal_esplanade_1k.pic"
                    backgroundUrl="/img/demsky/demsky-raster.svg" />
                <div className="absolute bottom-24 hidden w-full lg:flex">
                    <div className="w-full max-w-screen-2xl m-auto">
                        <img className="w-40 ml-4" src="img/demsky/demsky-ar-clip.png" />
                    </div>
                </div>
            </header>

            <ActionBar />

            <img className="hidden sm:inline-block lg:hidden self-center mb-20" src="img/demsky/demsky-ar-clip.png" />

            <main className="relative m-auto px-8 flex flex-col z-0 max-w-screen-2xl">

                <p className="text-indent text-2xl sm:text-4xl lg:text-5xl xl:text-6xl">
                    A collaboration between modern artist <a href="#">Demsky</a> and <a href="#">Anima</a>, PHASE MN:01 Mirror is a digital sculpture and gateway to the metaverse. An extension of Demsky’s otherworldly physical works – Mirrors are augmented reality NFTs that can be evolved interactively through global exploration.
                </p>

                <img className="my-36 self-center shadow-card w-full sm:w-2/3 md:w-1/2 lg:w-1/3" src="img/demsky/demsky-card.png" width="100%" />

                <section className="flex flex-col mb-20 sm:mb-52 md:mb-64">
                    <hr />
                    <div className="grid grid-cols-12 gap-4">
                        <h2 className={`${styles.h2}`}>Overview</h2>
                        <p className={`${styles.p.lg} text-indent`}>Beginning as 8,888 similar augmented reality objects, each Mirror evolves based on its owner’s actions and through the activation of enigmatic coordinates around the world.<br /><br />Owners will join a community of explorers, transcending physical and digital boundaries in a blended reality that exists only in the camera metaverse.<br /><br />Once the community has activated enough of the revealed coordinates, PHASE MN:02 will be unlocked, a true collective digital creation, followed by PHASE MN:03, a final journey for the initial group of explorers.
                        </p>
                    </div>
                </section>

                <section className="flex flex-col mb-40 sm:mb-52 md:mb-64">
                    <hr />
                    <div className="grid grid-cols-12 gap-4">
                        <h2 className={`${styles.h2} md:col-span-10 z-10`}>Exploring  the  globe  evolves mirrors,  increasing  their uniqueness.</h2>
                        <Parallax speed={.1} as="figure" className="col-span-12 -mx-8 mb-8 sm:mx-0 sm:mb-12 md:col-span-10 md:col-start-3 lg:col-span-8 lg:col-start-5">
                            <img className="" src="img/demsky/demsky-photo-comp.png" width="100%" />
                        </Parallax>
                        <p className={`${styles.p.md} text-indent`}>Following the sale, a series of GPS coordinates of locations around the globe will be released for explorers to activate with their Mirrors.<br /><br />Each visited location transforms the Mirror, changing its appearance and increasing its rarity. The most prolific explorers will become a prominant part of the history of PHASE:MN, and will receive special rewards in future stages.
                        </p>
                    </div>
                </section>

                <section className="flex flex-col mb-20 sm:mb-52 md:mb-64 relative">
                    <Parallax speed={.05} className="grid grid-cols-12 gap-4 gap-y-0 relative">
                        <div className="col-span-12 -mx-8 sm:mx-0 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 overflow-hidden">
                            <Parallax speed={.1} as="figure" className="w-full">
                                <img className="transform scale-125 origin-top" src="img/demsky/demsky-location.png" width="100%" />
                            </Parallax>
                        </div>
                        <figure className="col-span-12 -mx-8 sm:mx-0 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">
                            <img src="img/demsky/demsky-metallic.png" width="100%" />
                        </figure>
                    </Parallax>
                    <Parallax speed={.2} className="grid grid-cols-12 gap-4 gap-y-0 pt-10 absolute top-0 left-0 w-full h-full font-mono text-sm sm:text-base">
                        <p className="col-span-6 sm:col-span-4">54.2312716, -4.569504</p>
                        <p className="col-span-6 sm:col-span-4">Isle of Man</p>
                        <p className="col-span-6 sm:col-span-4">gcsu80884cfb5</p>
                        <p className="col-span-6 sm:col-span-4">@username</p>
                        <p className="col-span-6 sm:col-span-4">2021 11 23 05:48 UTC</p>
                        <p className="col-span-6 sm:col-span-4">7 of 339</p>
                    </Parallax>
                    <div className="grid grid-cols-12 gap-4 gap-y-0 absolute -bottom-1/4">
                        <div className="col-span-6 sm:col-span-4 sm:col-start-3 md:col-start-2 lg:col-span-3 lg:col-start-4">
                            <img src="img/demsky/demsky-abstract.png" width="100%" />
                        </div>
                    </div>
                </section>

                <blockquote className="font-blockquote text-center text-4xl self-center sm:text-6xl lg:text-7xl md:w-5/6 lg:w-2/3 mb-40 sm:mb-52 md:mb-64">
                    “The outer world and everyone in it is just a mirror of our inner world.”
                </blockquote>

                <section className="flex flex-col mb-20 sm:mb-52 md:mb-64">
                    <div className="grid grid-cols-12 gap-4">
                        <h2 className={`${styles.h2} relative bg-contain bg-no-repeat bg-right z-10`} style={{ backgroundImage: 'url(\'img/demsky/demsky-raster-header.svg\')' }}>
                            The<br />Drop
                            <figure className="flex items-center h-full absolute w-1/6 sm:w-1/12 top-0 right-0 mr-2">
                                <img className="object-contain h-full" src="img/demsky/demsky-badge.png" height="100%" />
                            </figure>
                        </h2>
                        <div className="col-span-6 md:col-span-4 lg:col-span-3">
                            <h3 className="text-xl sm:text-3xl font-serif uppercase mb-6"><span className="font-blockquote text-5xl sm:text-7xl">1.</span><br />Edition</h3>
                            <p className="text-sm sm:text-base">A limited edition of 8,888 MN:01 Mirrors will be released, at a price of $200 per edition.<br /><br />Up to 15 editions can be purchased per day, using a credit card or crypto including Ethereum, BTC, DAI and USDC.
                            </p>
                        </div>
                        <div className="col-span-6 md:col-span-4 md:col-start-7 lg:col-span-3 lg:col-start-auto">
                            <h3 className="text-xl sm:text-3xl font-serif uppercase mb-6"><span className="font-blockquote text-5xl sm:text-7xl">2.</span><br />Pre-registration</h3>
                            <p className="text-sm sm:text-base">Buyers can pre-register to purchase up to 15 editions.<br /><br />When the drop date is announced, pre-registered buyers will be contacted to complete their purchases ahead of the open sale.
                            </p>
                        </div>
                        <div className="col-span-6 md:col-span-4 lg:col-span-3">
                            <h3 className="text-xl sm:text-3xl font-serif uppercase mb-6"><span className="font-blockquote text-5xl sm:text-7xl">3.</span><br />Open sale</h3>
                            <p className="text-sm sm:text-base">Any remaining editions will be sold openly, after which point the sale will be closed. Mirrors will be minted on Palm and transferred to buyers’ accounts.<br /><br />Once the open sale begins, the first location coordinates will be released to kick off global exploration and the evolution of the Mirrors.
                            </p>
                        </div>
                        <div className="col-span-6 md:col-span-4 md:col-start-7 lg:col-span-3 lg:col-start-auto">
                            <h3 className="text-xl sm:text-3xl font-serif uppercase mb-6"><span className="font-blockquote text-5xl sm:text-7xl">4.</span><br />Aftermarket</h3>
                            <p className="text-sm sm:text-base">Post-sale, Mirrors may be made available by their owners on secondary marketplaces like Nifty’s (Palm) and OpenSea.<br /><br />There is a 10% artist royalty on secondary sales.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="flex flex-col mb-20 sm:mb-52 md:mb-64">
                    <hr />
                    <div className="grid grid-cols-12 gap-4">
                        <h2 className={`${styles.h2} relative z-10`}>
                            The<br />blockchain
                            <figure className="absolute w-1/6 sm:w-1/12 top-0 right-0">
                                <img src="img/demsky/palm-logo.png" width="100%" />
                            </figure>
                        </h2>
                        <p className={`${styles.p.md} text-indent`}>PHASE MN:01 will be released on PALM, an Ethereum compatible, energy-efficient side chain, and can be bridged to Ethereum main net for resale on marketplaces like OpenSea.<br /><br />Attributes affecting the visual representation of each token are dynamic. They will be changed by the actions of their owners and the community – altering their NFT metadata dynamically, unexpectedly, and permanently.</p>
                    </div>
                </section>

                <section className="flex flex-col mb-20 sm:mb-32">
                    <hr />
                    <div className="grid grid-cols-12 gap-4 mb-8 sm:mb-12">
                        <div className="flex flex-col col-span-6 md:col-span-4">
                            <h2 className={`${styles.h2}`}>About<br />the<br />artist</h2>
                            <section className="justify-self-end mt-5 text-sm md:hidden">
                                <Socials />
                            </section>
                        </div>
                        <Parallax speed={.05} as="figure" className="col-span-6 pt-20 md:pt-0">
                            <img className="h-full object-cover" src="img/demsky/demsky-profile-picture.png" width="100%" height="100%" />
                        </Parallax>
                    </div>
                    <div className="grid grid-cols-12 gap-4">
                        <section className="hidden md:inline-block col-span-4">
                            <Socials />
                        </section>
                        <p className={`${styles.p.sm}`}>
                            DEMSKY J. (1979)
                            <br /><br />
                            Rooted in the graffiti boom of the 90s, Demsky is a self-taught modern artist internationally known for his work on the streets of more than fifty countries, on gallery walls, underwater, and now in the metaverse.
                            <br /><br />
                            His work balances the future and nostalgia, radical creativity and exact sciences, with influences across street art, Japanese mecha, and 80s arcade games. He explores the interconnected relationship of space and time, highlighting the glitches and animating the static.
                            <br /><br />
                            Demsky creates a true anachronism: a retro-futuristic vision immersed in impossible shapes, multiple dimensions, and organized chaos.
                        </p>
                    </div>
                </section>

                <section className="grid grid-cols-12 gap-4 mb-40">
                    <figure className="col-span-6 col-start-4 sm:col-span-3 sm:col-start-7 md:col-start-5 lg:col-span-2 lg:col-start-5">
                        <img src="/img/demsky/demsky-signature.svg" width="100%" />
                    </figure>
                </section>

            </main>
        </article>
    </Layout>;
}

export default Index;