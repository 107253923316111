import React from 'react';
import ReactDOM from 'react-dom';

import Index from './routes/index.js';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import './index.css';

ReactDOM.render(
    <React.StrictMode>
        <Router>

            <Switch>
                <Route path="/">
                    <Index />
                </Route>
            </Switch>

        </Router>
    </React.StrictMode>,
    document.getElementById( 'root' )
);
