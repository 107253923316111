import React, { useEffect, useRef, useState } from 'react';

import { useSpring, animated, config } from 'react-spring';
import { useScrollPosition } from './scroll-position';
import { useWindowSize } from './window-size';
import { useInView } from 'react-intersection-observer';

const Parallax = ( { children, speed, as, className, type } ) => {

    const AnimatedTag = animated( as || 'div' );
    const scrollPosition = useScrollPosition();
    const windowSize = useWindowSize();

    const distance = useRef( 0 );
    const percentage = useRef( 0 );

    const [ offset, setOffset ] = useState( 0 );
    const { ref, inView, entry } = useInView( { rootMargin: '100% 0px' } );

    useEffect( () => {

        if ( entry && entry.target && inView ) {

            distance.current = windowSize.height - entry.target.getBoundingClientRect().top;
            percentage.current = Math.round(
                distance.current / ( ( windowSize.height + entry.target.offsetHeight ) / 100 )
            );

            setOffset( ( percentage.current / 100 - .5 ) * 2 );

        }

    }, [ scrollPosition.y ] );

    const { translate3d, rotate3d } = useSpring( {
        rotate3d: `rotate3d(0, 0, 1, ${-offset * speed}deg)`,
        translate3d: `translate3d(0, ${-offset * speed * windowSize.height}px, 0)`,
        config: config.fast
    } );

    return <AnimatedTag style={{ transform: type === 'rotate3d' ? rotate3d : translate3d }} ref={ref} className={className}>
        {children}
    </AnimatedTag>;

};

export default Parallax;