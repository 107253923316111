import React, { useState, useEffect, useRef } from 'react';

const Marquee = ( { amount = 10, text } ) => {

    const renderCopies = () => {
        return Array( amount ).fill().map( ( key, index ) => {
            return <span key={index} className="animate-marquee" aria-hidden="true">
                {text}
            </span>;
        } );
    };

    return <section className="font-extended flex-1 overflow-hidden items-center hidden mx-2 sm:flex md:mx-4 lg:mx-6">
        <div className="flex whitespace-nowrap">
            <span className="animate-marquee">
                {text}
            </span>
            {renderCopies()}
        </div>
    </section>;

};

export default Marquee;
